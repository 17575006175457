<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        إضافة عميل
        <feather-icon icon="Edit2Icon" />
      </h4>

    </div>
    <validation-observer ref="addClientFormvalidate">
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>

          <b-col md="6">
            <b-form-group
              label="اسم العميل "
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Course Name"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="addClientForm.name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="رابط موقع العميل  "
              label-for="blog-edit-slug"
              class="mb-2"
            >

              <validation-provider
                #default="{ errors }"
                name="In-Link Name"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addClientForm.link"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                شعار العميل
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewE2"
                    :src="Tabimage"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>

                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group

                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                      >
                        <b-form-file
                          ref="refInputE2"
                          v-model="addClientForm.image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="اختر ملف"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="نص بديل للصورة"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addClientForm.alt_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>

          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              حفظ
            </b-button>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,

  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'

export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const Tabimage = ref('media/svg/files/blank-image.svg')
    const categoriesList = ref([])
    const form = ref()
    store.dispatch('categories/AllCategory')
      .then(response => {
        console.log('response', response.data.data)
        categoriesList.value = response.data.data
      })
    const addClientForm = reactive({
      image: '',
      name: '',
      link: '',
      alt_image: '',

    })
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addClientFormvalidate = ref()

    const save = () => {
      console.log(addClientForm)
      const formData = new FormData()

      addClientFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('link', addClientForm.link)
          formData.append('name', addClientForm.name)
          formData.append('image', addClientForm.image)

          formData.append('alt_image', addClientForm.alt_image)

          console.log('formData', formData)
          store.dispatch('clients/addClient', formData)
            .then(response => {
              form.value.reset()

              refPreviewE2.value.src = 'media/svg/files/blank-image.svg'
              Vue.swal({
                title: 'تم الاضافة بنجاح ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'حسنا',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })

          console.log('alt_header_image', addClientForm)
        }
      })
    }
    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addClientForm,
      inputImageRenderer,
      addClientFormvalidate,
      categoriesList,

      form,
      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
